<script setup>
import { ref, onBeforeMount, defineProps, defineEmits } from 'vue'
import _ from 'lodash'
import Button from '../../form/Button.vue'
import DotActions from './../../../components/estimate/item/location/DotActions.vue'
import Buttons from './../../../components/estimate/elements/Buttons.vue'
import api from '../../../services/wizard.service'
import ConfirmationModal from "@/components/item-wizard/components/modals/ConfirmationModal.vue";

const props = defineProps({
    item: Object,
    allData: Object,
    showViewAction: {
        type: Boolean,
        default: true
    },
    showDeleteAction: {
        type: Boolean,
        default: true
    }
})

const emits = defineEmits(['view', 'delete', 'apply'])

const view = () => {
    emits('view', packageDetails.value)
}

const showAction = ref(false)

const showDeleteConfirmation = ref(false)
const deleteStatus = ref('')
const applyStatus = ref('')

const deletePackage = (val) => {
    deleteStatus.value = api.status.LOADING
    api.deletePackage(props.item.id).then(res => {
        deleteStatus.value = api.status.SUCCESS
        emits('deleted', props.item)
    })

}

const apply = () => {
    emits('apply', props.item)
    // applyStatus.value = api.status.LOADING
    // const d = _.cloneDeep(props.allData)
    // api.nextStep( { ...d, selection: props.item } )
}

const status = ref('')
const packageDetails = ref(null)

onBeforeMount(() => {
    status.value = api.status.LOADING
    api.getPackage(props.item.id).then(res => {
        packageDetails.value = res
        status.value = api.status.SUCCESS
    }).catch(err => {
        status.value = api.status.ERROR
    })
})

</script>

<template>
     <ConfirmationModal
        message="Are you sure you want to delete this package?"
        :cancel_btn_data="{ type: 'primary', title: 'No' }"
        :confirm_btn_data="{ type: 'primary', title: 'Yes' }"
        @confirmClicked="deletePackage"
        v-model:show="showDeleteConfirmation"
      />
    <div class="tw-shadow tw-flex tw-flex-col tw-border tw-border-solid tw-border-gray-200">
        <div class="header tw-bg-primary tw-px-3 tw-py-1.5 tw-flex tw-justify-between tw-items-center">
            <span class="tw-w-10/12 tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden">{{ item.name }}</span>
            <div v-if="showViewAction || showDeleteAction" class="tw-relative">
                <div class="tw-flex tw-gap-3 tw-items-center">
                    <div v-if="showViewAction" class="tw-relative tw-cursor-pointer" @click="view">
                        <svg-icon-qouting data="fas fa-eye" class=""/>
                    </div>
                    <div v-if="showDeleteAction" @click="showAction = !showAction" class=" tw-relative tw-cursor-pointer tw-text-lg">
                        <svg-icon-qouting data="fas fa-ellipsis-h tw-text-lg" class=""/>
                    </div>
                </div>
                <div class="tw-fixed tw-top-0 tw-left-0 tw-right-0 tw-bottom-0 tw-z-10" v-if="showAction" @click="showAction = false">
                </div>
                <div v-if="showAction" class="tw-absolute tw-shadow tw-px-2 tw-py-0.5 tw-right-0 tw-z-10 tw-text-gray-900 tw-bg-white tw--bottom-2 tw-transform tw-translate-y-full tw-border tw-border-solid tw-border-gray-200"> 
                    <p class="tw-cursor-pointer tw-flex tw-gap-1.5" @click="showDeleteConfirmation = true"> 
                        <svg-icon-qouting data="fas fa-trash-alt" class=""/> 
                        <span>Delete</span>
                    </p>
                </div>
    
            </div>
        </div>
        <div class="tw-flex-1 tw-p-3 tw-text-md tw-font-normal tw-flex tw-flex-col tw-justify-between content">
            <div class="tw-flex tw-flex-col tw-gap-3">
                <p class="tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden">Name: {{ item.name }}</p>
                <p class="tw-whitespace-nowrap tw-overflow-ellipsis tw-overflow-hidden">Description: {{ item.description }}</p>
                <div class="tw-flex tw-gap-2">
                    <p>Options:</p>
                    <div class="tw-flex tw-gap-2 tw-flex-wrap">
                        <span v-if="status === api.status.LOADING">Loading...</span>
                        <template v-if="status === api.status.SUCCESS">
                            <span v-for="(opt, index) in packageDetails.item_packages_options" :key="index">
                                {{ opt.option_code }}{{index !== (packageDetails.item_packages_options.length - 1)? ',' : '' }}
                            </span>
                        </template>
                    </div>
                </div>
            </div>

            <div class="tw-w-full tw-mt-3">
                <slot name="actionButton">
                    <Button class="tw-w-full btn-view" :disabled="status === api.status.LOADING" :data="{title: status === api.status.LOADING? 'Loading': 'Update', type: 'primary', event: 'view', icon: 'fas fa-edit'}" @view="view"/>
                </slot>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    ::v-deep .top__actions{
        display: flex !important;
    }

    .content {
        color: rgba(28, 40, 51, 0.8);
        font-size: 13px;
    }

    .header {
        background: var(--PrimaryColour);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        color: white;
    }

    .title-text {
        width: 100%;
        max-width: 100vw;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        text-align: left;
        font-family: Sansation;
    }

</style>