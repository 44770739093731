<script setup>
import { ref, computed, reactive, defineEmits, defineProps } from 'vue'
import api from '../../../services/wizard.service'
import Button from '../../form/Button.vue'
import InputGroup from '../../form/InputGroup.vue'
import Checkbox from '../../form/Checkbox.vue'
import _ from 'lodash'

const emits = defineEmits(['back'])

const props = defineProps({
    item: {
        type: Object,
        default() {
            return {
                name: '',
                description: '',
                id: null,
                item_packages_options: []
            }
        }
    },
    data: Object
})

const typeId = computed(() => props.data.steps.find(i => i.name === 'Type').data.id )
const styleId = computed(() => props.data.steps.find(i => i.name === 'Style').data.id )
const gradeId = computed(() => props.data.steps.find(i => i.name === 'Grade').data.id )

const options = computed(() => props.data.steps.filter(i => i.option_code && ['ACC', 'ADD'].includes(i.option_code)) )

const selectedPreviously = computed(() =>
    options.value.map(i => {
        return props.item.item_packages_options.find(x => x.option_code === i.option_code) || undefined
    }
))

const selectedOptions = ref(selectedPreviously.value)
const selectedOptionCodes = computed(() => selectedOptions.value.filter(i => i !== undefined).map(i => i.option_code))

const form = reactive({
    name: props.item.name,
    description: props.item.description,
    id: props.item.id,
})

const nameError = ref(null)
const descError = ref(null)
const optionError = ref(null)

const optionClicked = (index, checked, val) => {
    selectedOptions.value[index] = checked? val: undefined
}

const back = (val) => {
    emits('back', val)
}

const statusCreate = ref('')

const save = () => {
    nameError.value = null
    descError.value = null
    optionError.value = null

    if(!form.name) return nameError.value = 'Name is required'
    // if(!form.description) return descError.value = 'Description is required'
    if(!selectedOptionCodes.value.length) return optionError.value = 'Please select an option.'


    statusCreate.value = api.status.LOADING

    const payload = {
        name: form.name,
        description: form.description,
        options: selectedOptionCodes.value,
        ...(_.pick(props.data, [
            "steps"
        ])
        ),
      mode: 'dealer'
    }

    if(form.id) {
        api.updatePackage(form.id, payload).then(res => {
            if(res.errors) {
                statusCreate.value = api.status.ERROR
                console.error('Error creating package', res.errors)
                return
            }
            statusCreate.value = api.status.SUCCESS
            back(true)
        })

    }else {
        api.createPackage(payload).then(res => {
            if(res.errors) {
                statusCreate.value = api.status.ERROR
                console.error('Error crearing package', res.errors)
                return
            }
            statusCreate.value = api.status.SUCCESS
            back(true)
        })
    }

}

</script>

<template>
    <div class="tw-flex tw-flex-col tw-overflow-hidden">
        <div>
            <svg-icon-qouting  class="btn_back" data="fas fa-arrow-left" @click="back(false)"/>
            <div class="title-text tw-mb-10">{{ form.id? 'Package Details': 'Create a package' }}</div>
        </div>
        <div class="tw-flex-1 tw-overflow-auto">
            <div class="tw-w-full md:tw-w-3/4 lg:tw-w-1/2 tw-flex tw-flex-col tw-gap-4">
                <InputGroup class=""  :data="{label: 'Name', placeholder: 'Name', type: 'text', error: nameError }" v-model="form.name" :focusOnMount="true" />
                <InputGroup class=""  :data="{label: 'Description', placeholder: 'Description', type: 'text', error: descError }" v-model="form.description">
                  <template #input>
                    <textarea v-model="form.description" rows="8" cols="33" placeholder="Description"/>
                  </template>
                </InputGroup>

                <p>Select Options:</p>
                <div class="form-group__error" v-if="optionError">{{ optionError }}</div>
                <div class="tw-w-full">
                    <div class=""
                        v-for="(opt, index) in options"
                        :key="opt.option_code"
                    >
                        <Checkbox
                            class="item__checkbox"
                            :data="{ key: opt.option_code, label: opt.option }"
                            :modelValue="selectedOptions[index]? true: false"
                            @update:modelValue="(e) => optionClicked(index, e, opt)"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="tw-py-2">
            <Button :data="{title: form.id? 'Update': 'Save', type: 'primary', event: 'saveChanges', loading: statusCreate === api.status.LOADING, icon: 'fas fa-save'}" @saveChanges="save"/>
        </div>
    </div>
</template>

<style scoped lang="scss">
    textarea {
      width: 100%;
      font-family: "Helvetica", "sans-serif";
      border: 1px solid rgba(11, 53, 83, 0.2);
      padding: 10px;
    }

    .header {
        background: var(--PrimaryColour);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        color: white;
    }

    .title-text {
        width: 100%;
        max-width: 100vw;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        text-align: left;
        font-family: Sansation;
    }

    .btn_back {
        position: absolute;
        left: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 100;
        width: 15px;
        height: 15px;
    }

    .form-group__error {
        font-size: 14px;
        line-height: 140%;
        color: #DB5151;
        text-align: left;
    }

</style>
