<script setup>

import { ref, watch, onMounted, computed, defineProps, defineEmits } from 'vue'
import Modal from '../../elements/Modal.vue'
import api from '../../../services/wizard.service'
import Loader from '../../elements/Loader.vue'
import Button from '../../form/Button.vue'
import PackageCardItem from './PackageCardItem.vue'
import CreateForm from './CreateForm.vue'

const props = defineProps({
    data: Object
})

const show = ref(true)

const emits = defineEmits(['close'])

const showCreateForm = ref(false)
const status = ref('')
const packages = ref([])

const typeId = computed(() => props.data.steps.find(i => i.name === 'Type').data.id )
const styleId = computed(() => props.data.steps.find(i => i.name === 'Style').data.id )
const gradeId = computed(() => props.data.steps.find(i => i.name === 'Grade').data.id )


watch(show, (val) => {
    if(!val) {
        emits('close')
    }
})

onMounted(() => {
    getPackages()
})

const getPackages = () => {
    status.value = api.status.LOADING

    api.getPackages(typeId.value, styleId.value, gradeId.value).then(res => {
        packages.value = res
        status.value = api.status.SUCCESS
    }).catch(err => {
        status.value = api.status.ERROR
    })
}

const handleCreateButton = () => {
    showCreateForm.value = true
}

const selectedPackage = ref(undefined)

const backToListings = (exit) => {
    showCreateForm.value = false
    selectedPackage.value = undefined

    if(exit) {
        show.value = false
    }
}

const deletePackage = (item) => {
    // status.value = api.status.LOADING

    // api.deletePackage(item.id).then(res => {
        getPackages()
    // })
}

const viewPackage = (item) => {
    selectedPackage.value = item
    showCreateForm.value = true
}


</script>

<template>
    <teleport to="body">
        <Modal class="packages-modal" v-model="show">
            <div class="tw-w-full tw-flex tw-justify-center- sm:tw-px-10 tw-flex-col tw-h-full tw-pt-12">
                <Loader class="wizard__loader-" customClass="big" v-if="status === api.status.LOADING" />

                <div v-show="!showCreateForm" class="tw-overflow-hidden">
                    <div v-if="status === api.status.SUCCESS && packages.length === 0" class="tw-flex tw-flex-col tw-gap-4 tw-justify-center tw-items-center tw-h-full">
                        <p class="tw-text-2xl tw-font-extralight tw-text-gray-400 tw-text-center tw-max-w-lg">You have not created any packages for this product yet.</p>
                        <Button class=""
                            :data=" {
                                type: 'primary',
                                title: 'Create a package',
                                event: 'createPackage',
                                icon: 'far fa-clone ',
                            }"
                            @click="handleCreateButton"
                        />

                    </div>
                    <div v-if="status === api.status.SUCCESS && packages.length" class="tw-flex tw-flex-col tw-h-full">

                        <div class="tw-mb-4 tw-uppercase title-text tw-flex tw-items-center tw-gap-4">
                            <span> Packages for this product </span>
                            <Button
                                class=""
                                title="Create new package"
                                :data=" {
                                    type: 'primary',
                                    title: '',
                                    event: 'createPackage',
                                    icon: 'far fa-plus ',
                                }"
                                @click="handleCreateButton"
                            />
                        </div>

                        <div class="tw-flex-1 tw-flex-col tw-inline-flex tw-overflow-auto">
                            <div class="sm:tw-grid tw-grid-cols-2 tw-gap-4 tw-pr-2">
                                <PackageCardItem v-for="item in packages" :key="item.id" :item = "item" :allData="data" @view="viewPackage" @deleted="deletePackage"/>
                            </div>

                        </div>
                    </div>
                </div>

                <template v-if="showCreateForm" class="">
                    <CreateForm @back="backToListings" :item="selectedPackage" :data="data"/>
                </template>
            </div>
        </Modal>
    </teleport>
</template>

<style scoped lang="scss">

    .content {
        color: rgba(28, 40, 51, 0.8);
        font-size: 13px;
    }

    .header {
        background: var(--PrimaryColour);
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 700;
        color: white;
    }

    .title-text {
        width: 100%;
        max-width: 100vw;
        font-size: 24px;
        line-height: 27px;
        text-transform: uppercase;
        color: #000000;
        font-weight: bold;
        text-align: left;
        font-family: Sansation;
    }

    .btn_back {
        position: absolute;
        left: 10px;
        top: 10px;
        cursor: pointer;
        z-index: 100;
        width: 15px;
        height: 15px;
    }

    ::v-deep .btn-view.btn_primary {
        background: var(--SecondaryColour)
    }

    ::v-deep .modal {
        width: calc(100% - 60px);
        max-width: 1140px;
        height: calc(100% - 60px);
        max-height: 700px;
        padding: 10px !important;

        @media(min-width: 768px) {
            max-width: 700px;
            max-height: 650px;
        }

    }

    ::v-deep .icons__wrapper.btn__icon {
        margin-right: 0;
    }




</style>
